@import './fonts/fonts.css';

*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
/* Scroll bar stylings */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #EDEDED;
    border-radius: 32px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #3D4C4F; 
    border-radius: 32px;
}

html{
    scroll-behavior: smooth;
}


p{
    margin-bottom: 0 !important;
}

ul{
    list-style: none;
}
a{
    text-decoration: none;
}
img,video{
    max-width: 100%;
    height: auto;
}

button,input{
    border: none;
    font-size: var(--normal-font-size);
}

button{
    cursor: pointer;
    outline: none !important;
}

input{
    outline: none !important;
}
.invalid-feedback{
  font-size: 16px !important;
}
