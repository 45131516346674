.reset-password-presentational {
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;

    overflow: hidden;

    .reset-password-content {
        z-index: 1;
        background: #1E2A2C;
        border-radius: 43px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4em 1.5em 1.5em 1.5em;
        width: 374px;
        color: #AFC3BC;
        font-family: groteskText;
        > .title {
            font-size: 19px;
            font-weight: 700;
            line-height: 24px;
            font-family: pitchsan;
            text-align: center;
            text-transform: uppercase;
        }
        .success-message {
          font-size: 15px;
        }
        > .reset-form {
            .reset-input {
                background: #fbfbfb;
                border-radius: 272px;
                padding: 0.75em 1em;
                text-align: left;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.155em;
                color: #1e555c;
                width: 325px;
            }
            .reset-btn {
                padding: 0.7em 1em;
                background: #AFC3BC;
                color: #1e555c;
                border-radius: 272px;

                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.155em;
                text-transform: uppercase;

                font-family: pitchsan;

                border: unset !important;
            }
            .circle {
              width: 0.75em;
              height: 0.75em;
              margin-right: 0.5em;
              margin-top: 0.1em;
              border-radius: 50%;
              display: inline-block;
              &.empty {
                border: 1px solid #AFC3BC;
              }
              &.filled {
                background-color: #AFC3BC;
              }
            }
            .password-reveal {
              cursor: pointer;
              position: absolute;
              margin-left: 8em;
              &.closed {
                margin-top: -1.65em;
              }
              &.open {
                margin-top: -1.75em;
              }
            }
        }
    }
}
