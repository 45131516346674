@font-face {
  font-family: 'groteskText';
  src: url('./static/FoundersGroteskTextApp-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
    font-family: 'pitchsan';
    src: url('./static/PitchSans-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}
